/* Defaults */

html,
body {
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

body {
  margin: 0;
  font-family: "Poppins", Sans-Serif;
  font-weight: 200;
}

a {
  text-decoration: none;
  color: #eb593c;
  transition: 0.35s;
}

a:hover {
  color: #a12810;
  transition: 0.4s;
}

*:focus {
  outline: none !important;
}

textarea,
input {
  outline: none;
}

a.override {
  text-decoration: none;
  color: inherit;
}

mark {
  background-color: #fad2ca;
}

/* Default Typography */

h1 {
  font-family: "Abril Fatface", Cursive;
  font-weight: inherit;
}

.color-white {
  color: white;
}

.color-orange {
  color: #eb593c;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

/* Animations */

@keyframes pulse {
  50% {
    opacity: 0.6;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Default Components */

button {
  color: #eb593c;
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #eb593c;
  font-family: "Poppins", Sans-Serif;
  height: 40px;
  min-width: 160px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.35s;
  outline: none;
}

button:hover {
  color: white;
  background-color: #eb593c;
  transition: 0.4s;
}

button:active {
  border: 2px solid white;
}

.btn-interior {
  display: flex;
  justify-content: center;
}

.btn-icon {
  margin-right: 8px;
  font-size: 20px;
}

.loader {
  font-size: 32px;
}

.type-icon-sm {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #b3b3b3;
  margin-right: 4px;
  flex-shrink: 0;
}

.type-icon-sm img {
  height: 16px;
  width: auto;
  margin-top: 4px;
  margin-left: 4px;
}

/* Containers */

.app-container {
  position: relative;
  margin-top: 40px;
}

.main-content-container {
  position: absolute;
}

.main-content-outer {
  width: 100vw;
  min-height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.main-content-outer.flex-start {
  align-items: flex-start;
  text-align: left;
}

.main-content-inner-fullscreen {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-content-outer-bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.main-content-outer-overlay {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.main-content-outer-bg.bg-home {
  background-image: url(https://cdn.surlydigital.com/cah/assets/other/hero-bg-2.jpg);
  background-size: cover;
  background-position-x: center;
}

.main-content-inner {
  position: absolute;
  width: 1260px;
}

/* Transition Groups */

.search-result-item-enter {
  opacity: 0;
  transform: translateX(-100px);
}

.search-result-item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.search-result-item-exit {
  opacity: 1;
  transform: translateX(0);
}

.search-result-item-exit-active {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}

/* Navigation */

.nav-container {
  position: fixed;
  height: 40px;
  top: 0;
  left: 0;
  background: #333;
  width: 100%;
  margin: 0;
  z-index: 4001;
}

.nav-container-mobile {
  display: none;
  position: fixed;
  height: 40px;
  top: 0;
  left: 0;
  background: #333;
  width: 100%;
  margin: 0;
  z-index: 4001;
}

.nav-container .nav-logo {
  position: absolute;
  left: 40px;
  width: 32px;
  padding: 8px;
  background-color: #333;
  border-radius: 50%;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5));
  transition: 0.35s;
}

.nav-container .nav-logo:hover {
  filter: drop-shadow(0px 3px 5px rgba(235, 89, 60, 0.8));
  transition: 0.35s;
}

.nav-container .nav-logo:active {
  filter: drop-shadow(0px 3px 5px rgba(255, 255, 255, 1));
}

.nav-links {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link {
  margin: 0 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Poppins", Sans-Serif;
  font-weight: 300;
  font-size: 12px;
  transition: 0.35s;
}

.nav-link a.active {
  color: #ddd;
  transition: 0.4s;
}

.slide-menu-container {
  display: none;
}

.env-label {
  position: absolute;
  top: 0;
  right: 40px;
  line-height: 40px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Poppins", Sans-Serif;
  font-weight: 300;
  font-size: 12px;
}

/* Index */

.home-logo {
  height: 200px;
}

.home-links-container {
  margin-top: 40px;
}

.home-links-container > * {
  margin: 0 8px;
}

/* Discography */

.discography-container {
  margin: 40px 0;
}

.disc-album-container {
  margin-bottom: 40px;
}

.album-track-wrap {
  display: flex;
  margin-bottom: 12px;
}

.album-track-wrap .track-num {
  text-align: left;
  width: 24px;
}

.disc-album-header-wrap {
  position: relative;
  height: 200px;
  overflow: hidden;
  border: 1px solid #eee;
  border-radius: 12px;
}

.disc-album-header-img {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 110%;
  height: 110%;
  filter: blur(8px) grayscale(100%);
  background-color: #333;
  background-size: cover;
  background-position: center;
}

h1.disc-album-name {
  margin: 0 0 12px 0;
}

.disc-album-header-info-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 220px;
  height: 100%;
  color: white;
}

.disc-album-cover-img {
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: #ddd;
  background-size: cover;
}

.disc-album-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #333 200px, #eee);
  opacity: 0.8;
}

.track-listing-wrap {
  display: inline-block;
  position: relative;
  margin: 20px 0 20px 116px;
}

.track-listing-wrap::before {
  content: " ";
  position: absolute;
  top: -20px;
  left: -20px;
  height: calc(100% + 20px);
  width: 8px;
  background-color: #eee;
  z-index: -1;
}

.track-bonus {
  margin-left: 2px;
}

.track-annotatior-wrap {
  margin-left: 12px;
  color: #b3b3b3;
}

.discography-container-msg-wrap {
  text-align: center;
}

.discography-container-msg-wrap .discography-container-msg {
  margin-bottom: 12px;
}

/* Lyrics */

.song-container {
  display: flex;
}

.song-lyrics-container {
  width: calc(100% - 680px);
  padding: 0 20px;
  height: calc(100vh - 40px);
  overflow: scroll;
}

h1.song-title {
  cursor: pointer;
}

.ref-anchor {
  color: #eb593c;
  cursor: pointer;
  font-weight: 300;
  transition: 0.35s;
}

.ref-anchor:hover {
  color: #b32d12;
  transition: 0.4s;
}

.ref-anchor.selected {
  background-color: #eee;
}

.song-lyrics-wrapper {
  margin-bottom: 40px;
}

.next-track-wrap {
  margin-bottom: 40px;
}

.next-track-wrap .next-track-name {
  font-family: "Abril Fatface";
}

/* Lyrics/Discography */

.song-discography-container {
  width: 200px;
  padding: 0 20px;
  height: calc(100vh - 40px);
  overflow: scroll;
  border-right: 1px solid #eee;
  background-color: white;
  transition: 0.35s;
}

.discography-panel-container {
  padding-bottom: 40px;
}

.discography-panel-msg-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lyrics-album-wrap {
  text-align: center;
  margin-bottom: 40px;
}

.lyrics-album-img {
  height: 100px;
  width: 100px;
  background-color: #b3b3b3;
  border: 1px solid #b3b3b3;
  background-size: cover;
  margin: 20px auto;
  cursor: pointer;
  transition: 0.35s;
}

.lyrics-album-img:hover {
  border: 1px solid #eb593c;
  transition: 0.4s;
}

.lyrics-album-track-container {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.35s;
}

.lyrics-album-track-wrap {
  margin-top: 12px;
  background-color: #eee;
  padding: 12px;
}

.lyrics-album-track-container.expand {
  max-height: 1000px;
  opacity: 1;
  transition: 0.4s;
}

.lyrics-album-track-link.active {
  color: inherit;
  cursor: default;
}

.lyrics-album-track-name {
  font-size: 14px;
  margin-bottom: 20px;
}

.spotify-player-wrap {
  position: relative;
  margin-bottom: 20px;
  height: 80px;
  width: 300px;
}

.spotify-player-wrap .spotify-player-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b3b3b3;
  border-radius: 12px;
  animation: pulse 1.2s linear infinite;
}

.spotify-player-wrap iframe {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spotify-player-wrap.loaded iframe {
  opacity: 1;
}

.mob-slider {
  display: none;
}

/* Song Summary */

.song-summary-wrap {
  animation: fadeIn 500ms;
}

.song-summary-title-wrap {
  position: relative;
  height: 140px;
  background: #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
}

.song-summary-title-img {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 110%;
  height: 110%;
  filter: blur(8px);
  background-color: #333;
  background-size: cover;
  background-position: center;
}

.song-summary-title-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: 0.4;
}

.song-summary-title {
  font-size: 20px;
  font-family: "Abril Fatface", Cursive;
  margin-bottom: 12px;
  z-index: 1;
}

.song-summary-info-wrap {
  padding: 20px;
}

.song-summary-info-elem {
  font-weight: 600;
  margin-bottom: 20px;
}

.song-summary-info-elem .song-summary-info-elem-value {
  font-weight: 200;
}

.song-summary-info-elem.opening-thoughts .opening-thoughts-wrap {
  font-weight: 200;
  margin-top: 8px;
}

/* Lyrics/Annotations */

.song-annotation-container {
  width: 400px;
  min-height: 400px;
  max-height: calc(100vh - 40px);
  text-align: left;
  background-color: #eee;
  border-radius: 12px;
  overflow: scroll;
}

.annotation-container {
  height: 100%;
}

.song-lyrics-msg-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.song-lyrics-msg-wrap .song-lyrics-msg {
  margin-bottom: 12px;
}

.annotation-wrap {
  padding: 20px;
  animation: fadeIn 500ms;
}

.annotation-title {
  font-size: 20px;
  font-family: "Abril Fatface", Cursive;
  margin-bottom: 12px;
}

.annotation-msg-wrap {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.annotation-attr-wrap {
  display: flex;
  align-items: center;
}

.annotation-attr-divider {
  margin: 0 8px;
}

.annotation-type,
.annotation-author,
.annotation-attr-year {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
}

.year-icon-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #b3b3b3;
  margin-right: 4px;
  font-size: 18px;
}

.pullquote {
  display: flex;
  margin: 20px;
  font-style: italic;
}

.pullquote-quote {
  margin-right: 8px;
}

.annotation-img-wrap {
  position: relative;
  width: 360px;
  height: 360px;
  margin: 20px 0;
}

.annotation-img-loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #b3b3b3;
  animation: pulse 1.2s linear infinite;
}

.annotation-img-wrap img {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.annotation-img-wrap.loaded img {
  opacity: 1;
}

.annotation-img-wrap.loaded .annotation-img-loading {
  visibility: hidden;
}

.annotation-details {
  text-transform: uppercase;
  font-weight: 600;
  color: #eb593c;
  margin-top: 12px;
  transition: 0.35s;
}

.annotation-details svg {
  margin-left: 0;
  transition: 0.35s;
}

.annotation-details:hover {
  color: #b32d12;
  transition: 0.4s;
}

.annotation-details:hover svg {
  margin-left: 4px;
  transition: 0.4s;
}

.annotation-minimize-wrap {
  display: none;
}

.annotation-soon-msg {
  width: 300px;
  background-color: #f8c3b9;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 12px;
  margin: 20px 0;
}

.tim-wrap {
  margin-top: 20px;
}

.tim-wrap .annotation-type {
  margin-bottom: 8px;
  background-color: #333;
  color: white;
  border-radius: 13px;
  font-size: 16px;
}

.tim-wrap .annotation-type .type-icon-sm {
  margin-right: 8px;
}

.annotation-details {
  width: fit-content;
}

/* Footer */

.footer {
  width: 100%;
  background-color: #333;
  padding: 40px 20px;
}

.footer-inner {
  width: 600px;
  margin: 0 auto;
  color: #b3b3b3;
  font-size: 14px;
}

.copyright {
  text-align: center;
}

.disclaimer {
  margin: 20px 0;
  text-align: left;
}

.powered-up-msg {
  margin-top: 40px;
  text-align: center;
}

/* Map */

.map-container {
  width: 100%;
  height: calc(100vh - 40px);
}

.map-info-window-wrap {
  text-align: left;
}

.map-info-window-label {
  font-family: "Abril Fatface";
  font-size: 20px;
  margin-bottom: 12px;
}

.map-info-window-desc {
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 200;
}

.map-info-window-details {
  text-transform: uppercase;
  font-weight: 600;
  color: #eb593c;
  margin: 12px 0;
  width: fit-content;
}

.map-info-window-details svg {
  margin-left: 0;
  transition: 0.35s;
}

.map-info-window-details:hover svg {
  margin-left: 4px;
  transition: 0.4s;
}

button[aria-label="Close"].gm-ui-hover-effect {
  visibility: hidden;
}

.btn-map-info-close {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  font-size: 16px;
}

.map-control-search-container {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 400px;
}

.map-control-search-wrapper {
  display: flex;
  align-items: center;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%), 0 4px 15px -5px rgb(0 0 0 / 0%);
}

.map-control-search-wrapper input {
  font-family: "Poppins", Sans-Serif;
  width: 100%;
  height: 32px;
  padding: 5px;
  padding-left: 30px;
  color: #333;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  appearance: none;
}

.map-control-search-wrapper input:focus {
  border-color: #eb593c;
}

.map-control-search-wrapper .input-icon {
  color: #b3b3b3;
  position: absolute;
  left: 10px;
}

.map-control-search-wrapper .btn-input-close {
  color: #333;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.map-control-search-wrapper .btn-input-close.disabled {
  color: #b3b3b3;
  cursor: default;
}

.map-filter-results-container {
  background-color: white;
  text-align: left;
  width: 100%;
  margin-top: 4px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%), 0 4px 15px -5px rgb(0 0 0 / 0%);
}

.map-filter-results-wrap {
  max-height: 0;
  overflow: hidden;
  transition: 0.35s;
}

.map-filter-results-wrap.show {
  max-height: 137px;
  overflow-y: scroll;
  transition: 0.35s;
}

.map-filter-result {
  font-size: 14px;
  padding: 4px 8px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.map-filter-results-action {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  background-color: #eb593c;
  color: white;
  cursor: pointer;
  transition: 0.35s;
}

.map-filter-results-action:hover {
  background-color: #b32d12;
  transition: 0.4s;
}

.map-filter-result .result-icon {
  color: #b3b3b3;
  margin-right: 8px;
}

.map-filter-result.active {
  background-color: #eee;
  font-weight: 600;
}

.map-filter-result.active .result-icon {
  color: #eb593c;
}

.map-overlay-message-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 1001;
}

.map-overlay-message {
  margin-bottom: 12px;
}

.map-info-window-img {
  height: 200px;
  width: 100%;
  background-color: #eee;
  margin: 12px 0;
  background-size: cover;
  background-position: center;
}

.map-control-zoom-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

.map-control-zoom-wrap {
  display: flex;
  align-items: center;
  width: 200px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 30%), 0 4px 15px -5px rgb(0 0 0 / 0%);
  background-color: white;
  height: 42px;
  border: 1px solid #b3b3b3;
}

.map-control-zoom-wrap select {
  appearance: none;
  border: none;
  background-color: inherit;
  height: inherit;
  cursor: pointer;
  font-family: "Poppins", Sans-Serif;
  padding: 0 10px;
}

.map-control-zoom-wrap .select-icon {
  border-right: 1px solid #b3b3b3;
  color: #b3b3b3;
  padding: 0 10px;
  height: 100%;
}

/* Search */

.search-result-container {
  height: 200px;
  background-color: #eee;
  border-radius: 12px;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  border: 2px solid #eee;
  transition: 0.35s;
}

.search-result-container:hover {
  border: 2px solid #333;
  transition: 0.4s;
}

.search-result-container:active {
  border-color: #eb593c;
}

.search-result-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 200px;
}

.search-result-inner {
  position: absolute;
  left: 200px;
  padding: 12px;
}

.search-result-inner .annotation-attr-wrap {
  margin-bottom: 12px;
}

.search-result-inner .annotation-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search-controls-container {
  display: flex;
  max-height: 500px;
  overflow: hidden;
  opacity: 1;
  transition: 0.35s;
}

.search-controls-container.hide-controls {
  max-height: 0;
  opacity: 0;
  transition: 0.4s;
}

.search-filters-container {
  position: sticky;
  top: 40px;
  z-index: 1001;
  padding: 20px 0;
  margin-bottom: 40px;
  background-color: rgba(256, 256, 256, 0.94);
}

.search-filters-minimize-wrap {
  display: none;
  width: 100%;
  text-align: center;
  color: white;
  background-color: #eb593c;
  cursor: pointer;
}

.search-control-container {
  width: 33%;
  padding: 0 12px;
}

.search-control-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-control-wrapper input {
  font-family: "Poppins", Sans-Serif;
  width: 100%;
  height: 32px;
  padding: 5px;
  padding-left: 30px;
  color: #333;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  appearance: none;
}

.search-control-wrapper input::placeholder {
  color: inherit;
  opacity: 1; /* Firefox */
}

.search-control-wrapper input:focus,
.search-control-wrapper select:focus {
  border-color: #eb593c;
}

.search-control-wrapper .input-icon {
  color: #b3b3b3;
  position: absolute;
  left: 10px;
}

.search-control-wrapper .btn-input-close {
  color: #333;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.search-control-wrapper .btn-input-close.disabled {
  color: #b3b3b3;
  cursor: default;
}

.search-control-wrapper select {
  appearance: none;
  cursor: pointer;
  font-family: "Poppins", Sans-Serif;
  padding: 0 10px;
  width: 100%;
  background-color: white;
  height: 44px;
  border: 1px solid #b3b3b3;
  border-radius: 0;
  appearance: none;
}

.search-msg-wrap {
  text-align: center;
}

.search-msg-wrap .search-msg {
  margin-bottom: 12px;
}

.search-total-wrap {
  margin-top: 20px;
  text-align: center;
}

.search-total-wrap .search-total-number {
  font-weight: 600;
}

/* Details */

.detail-hero-wrap {
  height: 200px;
  width: 100%;
  position: relative;
  background-color: #eee;
}

.detail-hero-wrap-bg-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.4;
}

.detail-hero-wrap-bg {
  margin-top: -12px;
  margin-left: -12px;
  width: 110%;
  height: 110%;
  background-size: cover;
  background-position: center;
  filter: blur(6px) grayscale(100%);
}

.detail-hero-wrap .detail-img {
  position: absolute;
  top: 26px;
  left: calc(50% - 170px - 4px);
  height: 340px;
  width: 340px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 4px solid #333;
  filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5));
}

.detail-info-container {
  margin: 210px auto 0 auto;
  width: 600px;
}

.detail-title {
  text-align: center;
}

.detail-title .annotation-attr-wrap {
  justify-content: center;
  text-align: left;
}

.detail-section {
  margin: 40px 0;
}

.detail-section-heading {
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.detail-loading-msg-wrap {
  text-align: center;
}

.mention-container-med {
  height: 80px;
  background-color: #eee;
  border-radius: 12px;
  margin-bottom: 12px;
  overflow: hidden;
  position: relative;
  border: 2px solid #eee;
  transition: 0.35s;
}

.mention-container-med:hover {
  border-color: #333;
  transition: 0.4;
}

.mention-container-med:active {
  border-color: #eb593c;
}

.mention-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center;
}

.mention-song-container {
  display: flex;
  height: calc(100% - 24px);
  position: absolute;
  top: 0;
  left: 80px;
  padding: 12px 20px;
  align-items: center;
}

.mention-song-title {
  font-family: "Abril Fatface", serif;
  font-size: 20px;
}

.map-detail-container {
  width: 100%;
  height: 320px;
}

/* About */

.about-container {
  width: 600px;
  margin: 40px auto;
}

.about-intro-wrap h1,
.about-contact-wrap h1 {
  text-align: center;
}

.about-intro-wrap {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin-bottom: 40px;
}

.about-contact-wrap {
  margin-bottom: 80px;
}

.contact-link-container {
  margin-top: 40px;
}

.contact-link-wrap {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}

.contact-icon-wrap {
  height: 48px;
  width: 48px;
  background-color: #b3b3b3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 50%;
}

.contact-icon-wrap.twitter {
  background-color: #1da1f2;
}

.contact-icon-wrap.facebook {
  background-color: #4267b2;
}

.contact-icon {
  font-size: 28px;
  color: white;
}

/* Tablet Media Queries */

@media screen and (max-width: 1259px) and (min-width: 768px) {
  .main-content-inner {
    width: calc(100% - 40px);
  }

  /* Lyrics */

  .song-discography-container {
    position: fixed;
    left: -241px;
    z-index: 2001;
  }

  .song-discography-container.show-panel {
    left: 0;
    transition: 0.4s;
  }

  .discography-screen {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.35s;
  }

  .discography-screen.hide-panel {
    opacity: 0;
    z-index: -1;
    transition: 0.4s;
  }

  .song-lyrics-container {
    width: calc(100% - 400px);
  }

  .mob-slider {
    display: block;
    position: fixed;
    left: 0px;
    background-color: white;
    font-size: 32px;
    top: calc(50% - 16px);
    border: 1px solid #eee;
    padding: 12px 8px 12px 0;
    color: #eb593c;
    border-radius: 0 12px 12px 0;
    cursor: pointer;
    z-index: 2001;
    transition: 0.35s;
  }

  .mob-slider:active {
    border-color: #eb593c;
  }

  .mob-slider.show-panel {
    left: 240px;
    transition: 0.4s;
  }

  .mob-slider.hide-slider {
    left: -30px;
  }
}

/* Mobile Media Queries */

@media only screen and (max-width: 767px) {
  /* Defaults */

  .home-logo {
    height: 140px;
  }

  .main-content-inner {
    width: calc(100% - 20px);
  }

  .home-links-container > * {
    margin: 0;
  }

  button {
    width: 100%;
    margin: 12px 0;
    max-width: 400px;
  }

  /* Discography */

  .disc-album-header-wrap {
    height: 100px;
  }

  .disc-album-header-img {
    filter: blur(4px) grayscale(100%);
  }

  .disc-album-cover-img {
    height: 100px;
    width: 100px;
  }

  .disc-album-header-info-wrap {
    left: 112px;
  }

  h1.disc-album-name {
    font-size: 20px;
  }

  .track-listing-wrap {
    margin: 12px 0 12px 40px;
  }

  .album-track-wrap {
    display: block;
  }

  .album-track-wrap .track-num {
    display: inline-block;
  }

  .track-annotatior-wrap {
    display: block;
    font-size: 12px;
    margin-left: 24px;
  }

  /* Lyrics */

  .song-discography-container {
    position: fixed;
    left: -240px;
    z-index: 2001;
  }

  .song-discography-container.show-panel {
    left: 0;
    transition: 0.4s;
  }

  .discography-screen {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.35s;
  }

  .discography-screen.hide-panel {
    opacity: 0;
    z-index: -1;
    transition: 0.4s;
  }

  .song-lyrics-container {
    width: 100%;
    min-height: calc(100vh - 40px);
    height: auto;
    overflow: auto;
  }

  h1.song-title {
    font-size: 24px;
    text-align: center;
  }

  .spotify-player-wrap {
    width: 100%;
  }

  .song-annotation-container {
    position: fixed;
    height: 90%;
    width: 90%;
    min-height: auto;
    max-height: none;
    transition: 0.6s ease-in-out;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5001;
  }

  .song-annotation-container.hide-mobile {
    top: 100%;
    height: 0;
  }

  .annotation-minimize-wrap {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    background-color: #eb593c;
    cursor: pointer;
    transition: 0.35s;
    z-index: 1;
  }

  .annotation-minimize-wrap:active {
    background-color: #b32d12;
  }

  .annotation-wrap .annotation-title {
    text-align: center;
  }

  .annotation-wrap .annotation-attr-wrap {
    justify-content: center;
  }

  .annotation-img-wrap {
    margin: 20px auto;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #333;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5));
  }

  .annotation-wrap {
    padding: 20px;
  }

  .annotation-type-wrap {
    width: min-content;
  }

  .tim-wrap .annotation-type {
    line-height: 20px;
    padding: 8px;
  }

  .song-screen {
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    transition: 0.35s;
    z-index: 3001;
  }

  .song-screen.hide-mobile {
    opacity: 0;
    z-index: -1;
    transition: 0.4s;
  }

  .mob-slider {
    display: block;
    position: fixed;
    left: 0px;
    background-color: white;
    font-size: 32px;
    top: calc(50% - 16px);
    border: 1px solid #eee;
    padding: 12px 8px 12px 0;
    color: #eb593c;
    border-radius: 0 12px 12px 0;
    cursor: pointer;
    z-index: 2001;
    transition: 0.35s;
  }

  .mob-slider:active {
    border-color: #eb593c;
  }

  .mob-slider.show-panel {
    left: 240px;
    transition: 0.4s;
  }

  .mob-slider.hide-slider {
    left: -30px;
  }

  .annotation-soon-msg {
    width: 100%;
  }

  /* Map */

  .map-control-search-container {
    width: calc(50% - 18px);
    top: 12px;
    left: 12px;
  }

  .map-control-zoom-container {
    width: calc(50% - 18px);
    top: 12px;
    right: 12px;
  }
  .map-control-zoom-wrap {
    width: 100%;
  }

  /* Search */

  .search-filters-container {
    display: block;
    margin-bottom: 12px;
    padding: 12px 0 0 0;
  }

  .search-control-container {
    width: 100%;
    margin: 12px 0;
    padding: 0;
  }

  .search-result-container {
    height: 100px;
  }

  .search-result-img {
    width: 100px;
    height: 100px;
  }

  .search-result-inner {
    left: 100px;
  }

  .search-result-inner .annotation-title {
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .search-result-inner .annotation-description {
    display: none;
  }

  .search-controls-container {
    display: block;
  }

  .search-filters-minimize-wrap {
    display: block;
  }

  .search-total-wrap {
    margin-top: 0;
    margin-bottom: 12px;
  }

  /* About */

  .about-container {
    width: auto;
    max-width: 400px;
  }

  .footer {
    width: auto;
  }

  .footer-inner {
    width: 100%;
  }

  /* Details */

  .detail-hero-wrap {
    height: 140px;
  }

  .detail-hero-wrap .detail-img {
    height: 220px;
    width: 220px;
    left: calc(50% - 110px - 4px);
  }

  .detail-info-container {
    width: 100%;
    margin: 130px auto 0 auto;
  }

  .detail-title h1 {
    font-size: 20px;
  }

  /* Navigation */

  .nav-container {
    display: none;
  }

  .nav-container-mobile {
    display: block;
    z-index: 4001;
  }

  .nav-container-mobile .btn-hamburger {
    position: absolute;
    left: 12px;
    height: 32px;
    width: 32px;
    padding: 8px;
    background-color: #333;
    border-radius: 50%;
    filter: drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: 0.35s;
  }

  .nav-container-mobile .btn-hamburger:active {
    filter: drop-shadow(0px 3px 5px rgba(255, 255, 255, 1));
    transition: 0.4s;
  }

  .slide-menu-container {
    display: block;
    position: fixed;
    top: 40px;
    left: -100vw;
    z-index: 4000;
    background: linear-gradient(#eb593c, #b32d12);
    width: 100vw;
    height: 100vh;
    border: none !important;
    transition: 0.35s;
  }

  .slide-menu-container .slide-menu-link {
    margin-left: 20px;
    color: white;
  }

  .slide-menu-container.menu-open {
    left: 0;
    transition: 0.4s;
  }

  .nav-label {
    width: 100%;
    text-align: center;
    height: 100%;
    line-height: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Poppins", Sans-Serif;
    font-weight: 300;
    font-size: 12px;
    color: white;
  }
}
